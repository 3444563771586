import * as React from "react"
import Layout from "../components/common/layout"
import Hero from "../components/hero"
import TextMedia from "../components/text-media"
import Title from "../components/title"
import VideoBlock from "../components/video-block"
import Masory from "../components/masory"
import {  graphql } from "gatsby"
import PreFooter from "../components/pre-footer"
import Benefits from "../components/benefits";
import FourthCard from "../components/fourth-card"
import { Helmet } from 'react-helmet'
const MinMoodLiveStream = ({ data }: any, location: any) => {
  console.log(data)
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const dataPrefooter = _data.find((item: any) => item.block === 'prefooter')
  const dataTextMedia = _data.find((item: any) => item.block === 'text-media')
  const dataTitle = _data.find((item: any) => item.block === 'title')
  const hero = _data.find((item: any) => item.block === 'hero')
  const dataBenefits = _data.find((item: any) => item.block === 'benefits')
  const fourthCard = _data.find((item: any) => item.block === 'fourth-card')
  return (
    <Layout>
        <Helmet
          title='Mind & mood live stream'
        />
       <Hero background="bg-blue-white" data={hero.data[0]} container={true}></Hero>
       <Benefits data={dataBenefits.data[0]} background="bg-white" className="pb-0 pt-0"></Benefits>
        <Title data={dataTitle.data[0]} background="bg-white"  ></Title>
        <VideoBlock 
          video="https://vimeo.com/93003441"
          title="Watch the 2 minute tour" 
          background="bg-white" 
          thumbnail="/images/place-holder-16x9.jpg" >
        </VideoBlock>
        {/* <Title data={dataTitle.data[1]}  background="bg-chalk"></Title> */}
        {/* <FourthCard data={fourthCard.data[0].card} background="bg-chalk"></FourthCard> */}
        <Title data={dataTitle.data[2]}  background="bg-blue-bale" ></Title>
        <Title data={dataTitle.data[3]}  background="bg-white"></Title>
        <TextMedia data={dataTextMedia.data[0]}  background="bg-white" ></TextMedia>
        <TextMedia data={dataTextMedia.data[1]} reverse={true}   background="bg-white" ></TextMedia>   
        <TextMedia data={dataTextMedia.data[2]}   background="bg-white" ></TextMedia>   
        <Title data={dataTitle.data[5]} background="bg-white" className="pt-0"></Title>
        <Masory background="bg-white"></Masory>
        <PreFooter data={dataPrefooter.data[0]} background="bg-blue"></PreFooter>  
    </Layout>
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["mind-mood-live-stream"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            hero {
              hight_light
              content
              images {
                id
                url
                width
              }
              title
              title_top
            }
            title_data {
              title
              content
              links{
                id
                text
                url
              }
            }
            text_media {
              title
              content
              image
              video {
                thumbnail
                title
                video
              }
              links{
                id
                text
                url
              }
            }
            benefits {
              id
              title
              image
            }
            prefooter_data {
              title
              content
              links {
                id
                text
                url
              }
            } 
          }
        }
      }
    }
  }
`
export default MinMoodLiveStream
